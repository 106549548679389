// @flow
import * as React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { FlexDiv } from '.';
import { AlternateText, PrimaryText } from '../Text';

interface ViewProps {
  containerStyle?: Object,

  lines: {
    text: string,
    style?: Object
  }[],

  selected?: boolean
}


export const Container = styled(FlexDiv)`
  background-color: ${theme.colors.primary.background};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);

  width: 100%;
  

  display: flex;

  
  padding:  12px;

  margin-right: 12px;
  margin-bottom: 12px;

  position: relative;
`;

const styles = {
  lines: {
    minWidth: 128,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const VerticalPadding = styled.div`
  margin-top: 12px;
`;

export function CardView({
  containerStyle, lines,
}: ViewProps) {
  return (
    <Container style={containerStyle}>
      {
        lines.map((x, i) => {
          if (x.text.length === 0) {
            return <VerticalPadding style={x.style} />;
          }

          const style = Object.assign({}, styles.lines, x.style)
          if (i === 0) {
            return <PrimaryText style={style}>{x.text}</PrimaryText>;
          }

          return <AlternateText style={style}>{x.text}</AlternateText>;
        })
      }
    </Container>
  );
}

export default {
  CardView,
  Container,
};
