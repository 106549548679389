import styled from 'styled-components'
import theme from '../../../theme'

export const Button = styled.button`
  padding: 9px 6px;  
  min-height: 25px;
  
  border-color: ${theme.colors.button.border};
  border-radius:  10px;
  
  background-color: ${theme.colors.button.background};

  color: ${theme.colors.button.content};
  
  :hover {
    cursor: pointer;
    background-color: ${theme.colors.button.contentImportant};
  }
`