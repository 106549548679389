import React from 'react'
import moment from 'moment'



import { FieldCalibration } from 'api/types'
import { Circle } from 'components/common'
import { SmallText, Description, Title } from 'components/common'
import { Container as CardContainer } from 'components/common/Containers/CardView'

import { FieldCalibrationScanList } from './FieldCalibrationScanList'
import { useHistory, useParams } from 'react-router';


type ViewProps = {
  calibration: FieldCalibration
  onClick: (f: FieldCalibration) => void,

  selected?: boolean
}


export function FieldCalibrationPreview({ calibration, onClick, selected }: ViewProps) {
  return <CardContainer
    selected={selected}
    onClick={() => onClick(calibration)}
    clickable
    alignItems="center"
    style={{ width: 200 }}
    wrap="nowrap"
    dir="row"
    justifyContent="space-around"
  >
    <div style={{ marginRight: 12 }}>
      <Circle radius={16} color={calibration.result ? "green" : "red"} />
    </div>
    <div style={{ width: 128 }}>
      <SmallText>{calibration.platform || 'Unknown'}</SmallText>
      <SmallText>{moment(calibration.createdAt).format("LL")}</SmallText>
      <SmallText>{calibration.ownerEmail}</SmallText>

    </div>
  </CardContainer >
}


type FieldCalibrationProps = {
  calibrations: FieldCalibration[]
}

export function FieldCalibrationView({ calibrations }: { calibrations: FieldCalibration[] }) {
  const history = useHistory()
  const { calibrationID, scanIndex } = useParams<{ calibrationID: string, scanIndex: string }>()
  const calibration = calibrations.find(x => x._id === calibrationID)

  if (!calibration) {
    return null;
  }


  return <CardContainer alignItems="center" wrap="nowrap" dir="row" justifyContent="flex-start">

    <div>
      <Title>Error Code</Title>
      <Description>{calibration.errorMessage}</Description>

      <Title>User</Title>
      <Description>{calibration.ownerEmail}</Description>

      <Title>Date of Field Calibration</Title>
      <Description>{moment(calibration.createdAt).format("LLL")}</Description>

      {
        calibration.platform && (<>
          <Title>App Name</Title>
          <Description>{calibration.platform}</Description>
        </ >)
      }

      <FieldCalibrationScanList
        onScanClick={(s, i) => {
          history.push(`/search/${calibration.serial}/calibrations/${calibrationID}/scans/${i}`)
        }}
        scans={calibration.scans}
        selectedIndex={parseInt(scanIndex)} />
    </div>

  </CardContainer>
}