export type HexCode = string

export interface ColorGroup {

  background: HexCode,

  // used for text and other foreground objects
  content: HexCode,

  // used for secondary text and content
  contentSubtle: HexCode,

  // used for IMPORTANT (bold, exciting) content
  // used for dividers
  contentImportant: HexCode,

  // used for border AROUND this group
  border: HexCode,
};



export interface ThemeProvider {
  colors: {
    background: HexCode,

    hover: ColorGroup
    primary: ColorGroup
    button: ColorGroup
  }
  dimensions: {
    viewport: {
      maxWidth: string;
      minWidth: string;
    }
  }
}


const colorDefs = {
  almostBlack: '#161616',
  darkGrey: '#232323',
  grey: '#343434',
  lightGrey: '#909090',
  almostWhite: '#CCCCCC',
  gold: '#F4B223',
  white: '#FFFFFF',
  blue: '#3A95CE',
};

const theme: ThemeProvider = {
  colors: {
    background: colorDefs.almostBlack,

    primary: {
      background: colorDefs.darkGrey,
      content: colorDefs.white,
      contentSubtle: colorDefs.lightGrey,
      contentImportant: colorDefs.gold,
      border: colorDefs.darkGrey,

    },
    hover: {
      background: colorDefs.gold,
      content: colorDefs.almostBlack,
      contentSubtle: colorDefs.lightGrey,
      contentImportant: colorDefs.darkGrey,
      border: colorDefs.gold,

    },
    button: {
      background: colorDefs.white,
      content: colorDefs.almostBlack,
      contentSubtle: colorDefs.grey,
      contentImportant: colorDefs.gold,
      border: colorDefs.grey,
    },
  },

  dimensions: {
    viewport: {
      maxWidth: '768px',
      minWidth: '300px',
    }
  }

}

export default theme