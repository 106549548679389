import React from 'react';
import { Modal } from 'components/common'
import Routes from 'components'


function App() {
  return (
    <Modal open >
      <Routes />
    </Modal>
  );
}

export default App;
