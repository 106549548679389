import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { VariableLogo } from 'components/common'
import DeviceSearch from './DeviceSearch'
import DeviceInfo from './DeviceSearchResult'



export default function Routes() {
  return <div style={{ padding: 24 }}>

    <VariableLogo />

    <BrowserRouter>
      <Route path={["/search", "/"]}  >
        <DeviceSearch />
      </Route>

      <Route path="/search/:serial" >
        <DeviceInfo />
      </Route>
    </BrowserRouter>
  </div >
}