import styled from 'styled-components'
import { ModelDetector } from 'api'

type ViewProps = {
  model: string
}



export const DeviceImage = styled.img.attrs(({ model }: ViewProps) => {
  if (ModelDetector.isColorMuse(model)) {
    return {
      src: '/images/color_muse.png'
    }
  }

  if (ModelDetector.isColorMuse2(model)) {
    return {
      src: '/images/color_muse_2.svg'
    }
  }

  if (ModelDetector.isSpectro(model)) {
    return {
      src: '/images/spectro.png'
    }
  }
})`

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  object-fit: contain;
  object-position: 50% 50%;
`