import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Loader = styled.div`
  width: 3em;
  height: 3em;
  border: 0.33em solid ${theme.colors.primary.content};
  border-left: 0.33em solid ${theme.colors.primary.contentImportant};
  border-radius: 50%;
  animation: load8 1.0s infinite linear;


  @keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

type ViewProps = {
  text: string
}


const Loading = ({ text }: ViewProps) => {
  const t = text || 'Loading...';

  return (
    <div style={{
      alignItems: 'center',
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '12.5%',
    }}
    >
      <Loader />
      <div style={{ marginLeft: 18, fontStyle: 'italic' }}>{t}</div>
    </div>
  );
};


export {
  Loading,
  Loader,
};
