import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../theme'


type TextProps = {
  fontWeight?: number | string,
  fontSize?: number | string,
  textAlign?: "center" | "left" | "right"
  lineHeight?: string
  fontStyle?: string
  color?: string

  style?: any
}

export const Text = styled.div<TextProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${props => props.textAlign || 'left'};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
`;
export const ErrorText = styled.p`
  color: ${theme.colors.primary.contentImportant};
`


export const PrimaryText = styled(Text) <TextProps>`
  color: ${theme.colors.primary.content};
`


export const AlternateText = styled(Text) <TextProps>`
  color: ${theme.colors.primary.contentSubtle};
`


export const SmallText = styled(Text)`
  font-size: 10px;
  margin: auto;
`

export const Title = styled(SmallText).attrs(({ color }) => ({ color: color || theme.colors.primary.contentSubtle }))`
  margin-top: 8px;
`
export const Description = styled(Text)`
  font-size: 16px;
  margin: auto;
`