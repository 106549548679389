import _ from 'lodash'
import { FlexDiv, Title, Description, Clickable } from 'components/common'
import * as React from 'react'
import styled from 'styled-components'
import utils from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Tile = styled(Clickable) <{ hex: string }>`
  width: 260px;
  height: 260px;
  
  margin: 4px;
  padding: 8px;

  background-color: ${({ hex }) => hex};

  position: relative;
`

const SmallTile = styled.div<{ hex: string }>`
  width: 48px;
  height: 48px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 4px;
  padding: 8px;

  background-color: ${({ hex }) => hex};

  border-style: solid;
  border-width: 1px;
  border-color: black;

  position: absolute;
  top: 0;
  right: 0;
`

type ScanViewProps = {
  index: number,
  onClick: (scan: any, index: number) => void,
  selected: boolean,
  scan: any
}

function ScanView({ scan, index, onClick, selected }: ScanViewProps) {

  return <Tile clickable selected={selected} onClick={() => onClick(scan, index)} hex={scan.hexes.actual} key={scan.hex} >

    <Title color="black">InApp ∆E</Title>
    <Description color='black'>{utils.roundDelta(scan.stats.corrected_delta)}</Description>

    <Title color="black">Secondary ∆E</Title>
    <Description color={'black'}>{utils.roundDelta(scan.stats.uncorrected_delta)}</Description>


    <Title color="black">Battery</Title>
    <Description color="black">{scan.battery ? utils.round(scan.battery) : 'Unknown'}</Description>


    <Title color="black">Is Charging</Title>
    <Description color="black">{scan.is_charging || 'Unknown'}</Description>

    <Title color="black">Lifetime Scan Count</Title>
    <Description color="black">{scan.scan_count || 'Unknown'}</Description>

    <Title color="black">Firmware Version</Title>
    <Description color="black">{scan.firmware_version || 'Unknown'}</Description>

    <SmallTile hex={scan.hexes.expected} >
      {scan.is_compensating && <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}
    </SmallTile>
  </Tile >
}

export function FieldCalibrationScanList({ selectedIndex, scans, onScanClick }: { selectedIndex: number | null, scans: any, onScanClick: (s: any, index: number) => void }) {
  return <FlexDiv dir="row">
    {scans.map((s: any, index: number) => <ScanView selected={selectedIndex === index} onClick={onScanClick} scan={s} index={index} key={_.uniqueId("scan_")} />)}
  </FlexDiv>
}

