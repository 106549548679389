import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 10%;
  
  margin-top: 2.5%;
  margin-bottom: 2.5%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img.attrs({
  src: '/images/logo.png',
  alt: 'Variable Logo'
})`
  
  max-height: 64px;

  object-fit: contain;

  @media only screen and (max-width: 1024px) {
    max-height: 32px;
  }

  @media only screen and (max-width: 500px) {
    max-height: 16px;
  }

  @media only screen and (max-width: 250px) {
    max-height: 8px;
  }
`
export const VariableLogo = () => {
  return <div style={{ paddingLeft: '15%', paddingRight: '15%' }}>
    <Container>
      <Image />
    </Container>
  </div>
}