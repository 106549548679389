import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

type CircleProps = {
  radius: number,

  color: string
}
export const Circle = styled.div<CircleProps>`
  background-color: ${({ color }) => color};
  
  height: ${({ radius }) => radius * 2}px;
  width: ${({ radius }) => radius * 2}px;


  border-radius: 50%;
`


interface ClickableOrSelectable {
  clickable?: boolean
  selected?: boolean
}
export const Clickable = styled.div<ClickableOrSelectable>`
 ${({ clickable }) => {
    if (clickable) {
      return `
    :hover {
      cursor: pointer;

      background-color: ${theme.colors.hover.background};
    }
  `
    }

    return ``
  }}
  
  ${({ selected }) => {
    if (selected) {
      return `
        border-color: ${theme.colors.hover.background};
        border-style: solid;
        border-width: 1px;
      `
    }
  }}
`
interface FlexDivProps extends ClickableOrSelectable {
  dir?: "row" | "column"
  alignItems?: "center" | string
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly"
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
}

export const FlexDiv = styled(Clickable) <FlexDivProps>`
  flex-direction: ${({ dir }) => dir || "column"};
  
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ wrap }) => wrap || "wrap"};

  display: flex;
`