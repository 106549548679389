import React from 'react'

import { FieldCalibrationPreview } from './FieldCalibration/FieldCalibration'
import { FieldCalibration } from '../../api/types'
import { FlexDiv, SmallText } from 'components/common'
import theme from 'theme'
import { useHistory, useParams } from 'react-router'
import utils from 'utils'


export type ViewProps = {
  calibrations: FieldCalibration[]
}



function useFieldCalibrations() {
  const history = useHistory()
  const { calibrationID } = useParams<{ calibrationID?: string }>()
  return {
    selectedCalibrationID: calibrationID,
    onCalibrationSelect: React.useCallback((c: FieldCalibration) => {
      history.push(`/search/${c.serial}/calibrations/${c._id}`)
    }, [history])
  }
}
export default function FieldCalibrationList({ calibrations }: ViewProps) {
  const { onCalibrationSelect, selectedCalibrationID } = useFieldCalibrations()

  const successCount = calibrations.filter(x => x.result).length
  const failureCount = calibrations.filter(x => !x.result).length
  return <div>
    <FlexDiv dir="row" style={{ marginBottom: 12, marginTop: 12 }}>
      <SmallText>Field Calibrations: {calibrations.length}</SmallText>
      <SmallText>Success Rate: {successCount} ({utils.round(successCount / calibrations.length * 100)}%)</SmallText>
      <SmallText>Failure Rate: {failureCount} ({utils.round(failureCount / calibrations.length * 100)}%)</SmallText>
    </FlexDiv>
    <FlexDiv wrap="nowrap" dir="row" style={{ ...theme.dimensions.viewport, overflow: 'hidden', overflowX: 'scroll' }}>

      {calibrations.map((c) => {
        return <FieldCalibrationPreview selected={c._id === selectedCalibrationID} onClick={onCalibrationSelect} calibration={c} key={c._id} />
      })}
    </FlexDiv >

  </div>
}