import React from 'react'
import moment from 'moment'
import { PrimaryText, Text } from 'components/common'
import { DeviceInfo } from 'api/types'


type ItemProps = {
  title: string,
  text: string,
  textColor?: "red" | "green" | string
}
export function Item({ title, text, textColor }: ItemProps) {
  return <div style={{ marginTop: 12 }} >
    <PrimaryText fontWeight="700" fontSize="12px">{title}</PrimaryText>
    <Text color={textColor} fontSize="14px" fontStyle="italic">{text}</Text>
  </div>
}

type DeviceViewProps = {
  device: DeviceInfo
}


export function WarrantStatus({ device }: DeviceViewProps) {
  if (!device.last_calibrated) {
    return null
  }

  const warrantyExpiration = moment(device.last_calibrated).add(1, 'y')
  const isWarrantyValid = warrantyExpiration.isAfter(moment.now())
  if (isWarrantyValid) {
    return <div>
      <Item title="Manufacturer Calibration Date" text={device.last_calibrated.format("LL")} />
      <Item textColor="green" title="Limited Warranty" text={`Expires on ${warrantyExpiration.format('LL')}`} />
    </div>
  }


  return <div>
    <Item title="Manufacturer Calibration Date" text={device.last_calibrated.format("LL")} />
    <Item textColor="red" title="Limited Warranty" text={`Expired on ${warrantyExpiration.format("LL")}`} />
  </div>
}

export function RecallInformation({ device }: { device: DeviceInfo }) {
  if (device.recall) {
    return <div style={{ marginTop: 12 }}>
      <Item title="Recall Status" text={device.recall.status} />
    </div>
  }

  return <div style={{ marginTop: 12 }}>
    <Item title="Recall Status" text="N/A" />
  </div>
}
