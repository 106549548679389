import moment from 'moment';
import { DeviceInfo } from './types';

// const baseURL = 'http://localhost:4400';
const baseURL = 'https://devices-bridge.vrbl.cloud';
export const api = {
  async lookup(serial: string): Promise<{
    item: DeviceInfo
  }> {
    const res = await fetch(`${baseURL}/api/v1/device/${serial}`);
    const body = await res.json();
    if (res.status >= 300) {
      throw new Error(body.message);
    }

    const { item } = body;
    if (item.last_calibrated) {
      item.last_calibrated = moment(item.last_calibrated);
    }

    return body as {
      item: DeviceInfo
    };
  }
};

function parseValue(model: string | number): number {
  if (typeof model === 'string') {
    return parseFloat(model);
  }
  return model;
}

function name(model: string | number) {
  if (ModelDetector.isColorMuse(model) || model === '') {
    return 'Color Muse';
  }

  if (ModelDetector.isColorMusePro(model)) {
    return 'Color Muse Pro';
  }

  if (ModelDetector.isSpectroSCE(model)) {
    return 'Spectro One (SCE)';
  }

  if (ModelDetector.isSpectroSCI(model)) {
    return 'Spectro One (SCI)';
  }

  if (ModelDetector.isColorMuse2(model)) {
    return 'Color Muse 2'
  }

  return 'Unknown Instrument';
}

export const ModelDetector = {
  isColorMuse2(model: string | number) {
    const m = parseValue(model);
    return m >= 16.0 && m < 17.0
  },
  isColorMuse(model: string | number): boolean {
    return parseValue(model) < 10.0;
  },

  isColorMusePro(model: string | number): boolean {
    const m = parseValue(model);
    return m >= 12.0 && m < 13.0;
  },

  isSpectro(model: string | number): boolean {
    const m = parseValue(model);
    return (m >= 10.0 && m <= 12.0) || (m >= 13.0 && m < 14.0);
  },

  isSpectroSCE(model: string | number): boolean {
    const m = parseValue(model);
    return m === 11.0;
  },

  isSpectroSCI(model: string | number): boolean {
    const m = parseValue(model);
    return m === 11.1;
  },
  name
};
