import React from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'

import { api } from 'api'
import { DeviceInfo } from 'api/types'
import { Loading, ErrorText } from 'components/common'
import DeviceInfoView from './DeviceInfoView'
import FieldCalibrationList from './FieldCalibrationList'
import { FieldCalibrationView } from './FieldCalibration/FieldCalibration'
import { BatteryVoltageChart, CalibrationLineChartRoute, ManufacturerTilesChart } from './FieldCalibration/Graphs'

type Tab = "calibrations" | "device_info"


function validateSerial(serial: string) {
  return serial.match(/[0-9A-Fa-f]{12}/g)?.length === 1
}

function useDeviceInfo() {
  const history = useHistory()
  const location = useLocation<{ device?: DeviceInfo }>()
  const { serial, calibrationID } = useParams<{ serial: string, calibrationID?: string }>()

  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setMessage] = React.useState('')
  const [device, setDevice] = React.useState<DeviceInfo | null>(location.state?.device || null)

  if (serial && device && device.serial !== serial) {
    setDevice(null);
    setLoading(true)
  }

  React.useEffect(() => {
    if (serial) {
      api.lookup(serial)
        .then((result) => {
          setDevice(result.item);
          setMessage('');
          setLoading(false);
        }).catch(err => {
          console.log('error')
          let errorMessage = ''

          if (err.message !== 'vi-not-found') {
            errorMessage = err.message
          } else if (validateSerial(serial)) {
            errorMessage = `Serial not found in system. ${serial}`
          } else {
            errorMessage = "Then entered serial is not a proper length"
          }

          setMessage(errorMessage);
          setDevice(null)
          setLoading(false);
        })
    }
  }, [serial])
  return {
    serial, loading, errorMessage, device,

    navigateToFieldCalibrations: React.useCallback(() => {
      //append calibrations to the end of the url.
      history.push(`/search/${serial}/calibrations`)
    }, [history, serial])
  }
}

export default React.memo(function () {
  const { serial, navigateToFieldCalibrations, loading, errorMessage, device } = useDeviceInfo()
  if (!serial) {
    return null
  }

  if (loading) {
    return <Loading text="Fetching Device Details" />
  }

  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>
  }

  if (!device) {
    return null
  }


  return <Switch>
    <Route exact path={["/search/:serial/calibrations/:calibrationID/scans/:scanIndex", "/search/:serial/calibrations", "/search/:serial/calibrations/:calibrationID",]}>
      <FieldCalibrationList calibrations={device.field_calibrations} />

      <Route path="/search/:serial/calibrations/:calibrationID" >
        {/* {selectedCalibration && <CalibrationLineChart calibration={selectedCalibration} />} */}
        <FieldCalibrationView calibrations={device.field_calibrations} />
      </Route>

      <Route exact path="/search/:serial/calibrations/:calibrationID/scans/:scanIndex" >
        <CalibrationLineChartRoute calibrations={device.field_calibrations} />
      </Route>
    </Route>
    <Route path="/search/:serial" exact >
      <DeviceInfoView device={(device as DeviceInfo)} onFieldCalibrationClick={navigateToFieldCalibrations} />
      <BatteryVoltageChart calibrations={device.field_calibrations} />
      <ManufacturerTilesChart tiles={device.manufacturer_tiles} />
    </Route>



  </Switch>

})

