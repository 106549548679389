import React from 'react'
import styled from 'styled-components'
import theme from 'theme'



interface WindowProps {
  open?: boolean
}
const ViewPort = styled.div<WindowProps>`
  display: ${(props) => props.open ? 'flex' : 'hidden'}; /* Hidden by default */
  
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  
  z-index: 1; /* Sit on top */
  
  overflow: auto; /* Enable scroll if needed */
  
  opacity: 0.95;
  background-color: ${theme.colors.primary.border}; /* Black w/ opacity */
`


interface ContentProps {

}
const ModalContent = styled.div<ContentProps>`
  min-width: ${theme.dimensions.viewport.minWidth};
  max-width: ${theme.dimensions.viewport.maxWidth};
  
  color: ${theme.colors.primary.content};
  background-color: ${theme.colors.background};
  margin: auto;
  padding: 8px;
  
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
`


const CloseIcon = styled.span.attrs({ content: '&times;' })`
  color: ${theme.colors.primary.contentSubtle};
  float: right;
  font-size: 18px;
  font-weight: bold;

  height: 24px;
  width: 24px;

  :after {
    content: '×';
  }

  :hover, :focus {
    font-size: 24px;
    text-decoration: none;
    color: ${theme.colors.primary.contentImportant};
    cursor: pointer;
  }
`


interface ModalProps extends WindowProps, ContentProps {
  children: React.ReactNode,
  onClose?: () => void
}

export default function ({ open, onClose, children }: ModalProps) {
  return <ViewPort open={open} >
    <ModalContent>
      {onClose && <CloseIcon onClick={onClose} />}
      {children}
    </ModalContent>
  </ViewPort>
}