import React from 'react'

import { ModelDetector } from 'api'
import { DeviceInfo } from 'api/types'
import { DeviceImage, Button } from 'components/common'
import { Container as CardContainer } from 'components/common/Containers/CardView'
import { Item, WarrantStatus, RecallInformation } from './components'

export type ViewProps = {
  device: DeviceInfo;
  onFieldCalibrationClick: () => void,
}
export default function DeviceInfoView({ device, onFieldCalibrationClick }: ViewProps) {
  return <CardContainer dir="row">

    <div style={{ padding: '10%', width: '25%' }}>
      <DeviceImage model={device.model} width="95%" />
    </div>

    <div style={{ marginLeft: 8 }}>
      <Item title="Serial" text={device.serial} />

      <Item title="Model" text={`${device.model} - ${ModelDetector.name(device.model)}`} />

      <Item title="Batch" text={device.batch} />
      {device.firmware_version && <Item title="Factory Firmware" text={device.firmware_version} />}

      {ModelDetector.isSpectro(device.model) && <Item title="Machine Learning Methodogy" text={!device.ann_version ? `Default (Unspecified)` : `${device.ann_version.name} - ${device.ann_version.version}`} />}
      {ModelDetector.isSpectro(device.model) && <Item title="Batch Master" text={device.ann_version?.target_serial ?? "Unknown"} />}

      <WarrantStatus device={device} />

      <RecallInformation device={device} />

      {ModelDetector.isSpectro(device.model) &&
        (<Button style={{ marginTop: 12 }} onClick={onFieldCalibrationClick} >
          Show Field Calibrations
        </Button>)
      }
    </div>
  </CardContainer >

}