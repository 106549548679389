import * as React from 'react'
import { Line, Scatter } from 'react-chartjs-2';

import { Container as CardContainer } from 'components/common/Containers/CardView'
import * as chartjs from 'chart.js';
import { useParams } from 'react-router';
import { DeviceInfo, FieldCalibration, SpectralScan } from 'api/types';
import moment from 'moment';
import Chart from 'chart.js/auto';
import _ from 'lodash';
import { Item } from '../components';
import utils from 'utils';

const manufacturerChartColors = {
  backgroundColor: 'rgb(99, 200, 132)',
  borderColor: 'rgba(99, 200, 132, 0.2)',
}
const fieldChartColors = {
  backgroundColor: 'rgb(210, 20, 100)',
  borderColor: 'rgb(210, 20, 100, 0.2)',
}


Chart.register(
  chartjs.CategoryScale,
  chartjs.LinearScale,
  chartjs.PointElement,
  chartjs.LineElement,
  chartjs.Title,
  chartjs.Tooltip,
  chartjs.Legend,
);

type DataHookReturnType = { data: chartjs.ChartData<"line">, options: chartjs.ChartOptions<"line"> }

function useChartOptions(calibration: FieldCalibration, scan: SpectralScan, hasPreviousSuccessfulFieldCalibration: boolean) {
  return {
    spectrum: useSpectrumChartOptions(scan),
    raw: useRawChartOptions(scan),
    corrected: useCorrectedSpectrumChartOptions(scan),
    correctionFactor: useCorrectionFactorChartOptions(calibration, scan, hasPreviousSuccessfulFieldCalibration)
  }
}

function useCorrectionFactorChartOptions(cal: FieldCalibration, scan: SpectralScan, hasPreviousSuccessfulFieldCalibration: boolean): null | DataHookReturnType {
  if (!hasPreviousSuccessfulFieldCalibration) {
    return null;
  }

  const spectrum: number[] = cal.scans[0].uncorrected_spectrum.curve;
  return {
    data: {
      labels: scan.senseValues.map((x: any, i: number) => scan.start + scan.step * i),
      datasets: [
        {
          pointRadius: 1.25,
          label: 'Field Correction Factor ( Previous )',
          data: scan.correction_factor,
          fill: false,
          ...manufacturerChartColors,
        },

        {
          pointRadius: 1.25,
          label: 'Field Correction Factor ( Attempt )',
          data: scan.wt_spectrum.curve.map((x: number, i: number) => { return x / spectrum[i] }),
          fill: false,
          ...fieldChartColors

        },
      ],
    },

    options:
    {
      plugins: {
        title: {
          display: true,
          text: "Correction Factors"
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Wavelength (nm)'
          },
        },

        y: {
          title: {
            display: true,
            text: "Intensity"
          },
        },

      }
    }
  }
}

function useSpectrumChartOptions(scan: any): DataHookReturnType {
  const data: chartjs.ChartData<"line"> = {

    labels: scan.senseValues.map((x: any, i: number) => scan.start + scan.step * i),
    datasets: [
      {
        pointRadius: 1.25,
        label: 'Manufacturer',
        data: scan.manufacturer_spectrum.curve,
        fill: false,
        ...manufacturerChartColors,
      },

      {
        pointRadius: 1.25,
        label: 'Field',
        data: scan.uncorrected_spectrum.curve,
        fill: false,
        ...fieldChartColors

      },
    ],
  };

  const options: chartjs.ChartOptions<"line"> = {
    plugins: {
      title: {
        display: true,
        text: "Field vs. Manufacturer Spectrums"
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Wavelength (nm)'
        },
        beginAtZero: true
      },

      y: {
        title: {
          display: true,
          text: "Intensity"
        },
        beginAtZero: true
      },

    }
  };

  return { data, options }
}

function useCorrectedSpectrumChartOptions(scan: any): DataHookReturnType {
  const data: chartjs.ChartData<"line"> = {

    labels: scan.senseValues.map((x: any, i: number) => scan.start + scan.step * i),
    datasets: [
      {
        pointRadius: 1.25,
        label: 'Manufacturer',
        data: scan.manufacturer_spectrum.curve,
        fill: false,
        ...manufacturerChartColors,
      },

      {
        pointRadius: 1.25,
        label: 'Field',
        data: scan.corrected_spectrum.curve,
        fill: false,
        ...fieldChartColors

      },
    ],
  };

  const options: chartjs.ChartOptions<"line"> = {
    plugins: {
      title: {
        display: true,
        text: "Field vs. Manufacturer Spectrums (In App)"
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Wavelength (nm)'
        },
        beginAtZero: true
      },

      y: {
        title: {
          display: true,
          text: "Intensity"
        },
        beginAtZero: true
      },

    }
  };

  return { data, options }
}

function useRawChartOptions(scan: any): { data: chartjs.ChartData<"line">, options: chartjs.ChartOptions<"line"> } {
  return {

    data: {
      labels: scan.senseValues.map((x: any, i: number) => scan.start + scan.step * i),
      datasets: [
        {
          pointRadius: 1.25,
          label: 'Manufacturer',
          data: scan.manufacturer_sense,
          fill: false,
          ...manufacturerChartColors,
        },
        {
          pointRadius: 1.25,
          label: 'Field',
          data: scan.senseValues,
          fill: false,
          ...fieldChartColors
        }]
    },

    options: {
      plugins: {
        title: {
          display: true,
          text: "Field vs. Manufacturer (Raw)"
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Wavelength (nm)'
          },
          beginAtZero: true
        },

        y: {
          title: {
            display: true,
            text: "Intensity"
          },
          beginAtZero: true
        },

      }
    }
  };
}


type ViewProps = {
  calibrations: FieldCalibration[]
}

export function BatteryVoltageChart({ calibrations }: ViewProps) {
  let items = [];
  for (let i = 0; i < calibrations.length; i++) {
    const voltages = _(calibrations[i].scans).map("battery").filter(x => !!x)
    if (voltages.size() > 0) {
      items.push({ x: i, y: voltages.mean() })
    }
  }

  let data: chartjs.ChartData<"scatter"> = {
    datasets: [{
      pointRadius: 2.5,
      label: 'Voltage at Scan',
      data: items.filter(x => !!x.y).map(d => ({ x: d.x!, y: d.y! })),
      fill: true,
      ...manufacturerChartColors,
    }]
  }
  return <CardContainer alignItems='center'>
    <Scatter data={data} options={{
      plugins: {
        title: {
          display: true,
          text: 'Battery over Calibration Attempts',
          padding: {
            top: 10,
            bottom: 30
          }
        }
      }
    }} />
  </CardContainer>;
}


export function ManufacturerTilesChart({ tiles: source }: { tiles?: DeviceInfo['manufacturer_tiles'] }) {
  if (!source) {
    return null;
  }

  return <CardContainer alignItems='center'>
    <h3>Manufacturer's Field Tile Data</h3>
    <Line data={
      {
        labels: source[0].spectrum.curve.map((x: any, i: number) => source[0].spectrum.start + source[0].spectrum.step * i),
        datasets: source.map(({ spectrum, hex }) => (
          {
            pointRadius: 1.25,
            label: 'Tile Scan',
            data: spectrum.curve,
            showLine: true,
            backgroundColor: hex,
            borderColor: hex,
            borderWidth: 1
          })
        )
      }}
      options={{
        plugins: {
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Wavelength (nm)'
            },
          },

          y: {
            title: {
              display: true,
              text: "Intensity"
            },
          },

        }
      }} />
    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 32 }}>
      {source.map(({ lab, lch, hex }) => {
        return <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <div style={{ marginTop: 12, width: 32, height: 32, backgroundColor: hex }} />
          <Item title={`Lab (${lab.illuminant} ${utils.toObserverisplayString(lab.observer)})`} text={`${lab.L.toFixed(2)}, ${lab.a.toFixed(2)}, ${lab.b.toFixed(2)}`} />
          <Item title={`Lch (${lch.illuminant} ${utils.toObserverisplayString(lch.observer)})`} text={`${lch.L.toFixed(2)}, ${lch.c.toFixed(2)}, ${lch.h.toFixed(2)}`} />
        </div>
      })
      }
    </div>

  </CardContainer >;
}

export function CalibrationLineChartRoute({ calibrations }: ViewProps) {
  const { calibrationID, scanIndex } = useParams<{ calibrationID: string, scanIndex: string }>()
  const calibration = calibrations.find(x => x._id === calibrationID)

  if (!calibrationID || !calibration) {
    return null
  }


  const index = parseInt(scanIndex)
  if (isNaN(index) || index >= calibration.scans.length) {
    return null
  }


  return <CalibrationLineChart
    previousSuccessfulCal={calibrations
      .filter(({ createdAt }) => moment(createdAt).isBefore(moment(calibration.createdAt)))
      .find(x => x.result)
    }
    calibration={calibration}
    scan={calibration.scans[index]}
  />
}

export function CalibrationLineChart({ previousSuccessfulCal, scan, calibration }: { previousSuccessfulCal: FieldCalibration | undefined, calibration: FieldCalibration, scan: any }) {
  const { spectrum, raw, corrected, correctionFactor } = useChartOptions(calibration, scan, previousSuccessfulCal !== undefined)
  return <>
    <CardContainer alignItems="center" justifyContent="center">
      <Line data={spectrum.data} options={spectrum.options} />
    </CardContainer>
    <CardContainer alignItems="center" justifyContent="center">
      <Line data={raw.data} options={raw.options} />
    </CardContainer>
    <CardContainer alignItems="center" justifyContent="center">
      <Line data={corrected.data} options={corrected.options} />
    </CardContainer>
    <CardContainer alignItems="center" justifyContent="center">
      {correctionFactor && <Line data={correctionFactor.data} options={correctionFactor.options} />}
      {!correctionFactor && <Text text="No Previous Field Calibration" />}
    </CardContainer>
  </>
}


function Text({ text }: { text: string }) {
  return <div>
    {text}
  </div>
}