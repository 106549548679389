import React from 'react'
import styled from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router'

import theme from 'theme'

const Input = styled.input`

  padding: 4px;

  border-color: transparent;
  border-style: solid;
  border-radius: 5px;
  width: 100%;

  outline: none;

  :focus {
    border-color: ${theme.colors.primary.contentImportant};
  }
`
function DeviceSearchComponent() {
  const match = useRouteMatch<{ serial: string }>('/search/:serial')

  const history = useHistory()
  const [serial, setSerial] = React.useState<string>(match?.params?.serial || '')

  return <div style={{ paddingTop: 24, paddingBottom: 12 }}>
    <Input
      value={serial}
      placeholder="Enter a serial number"
      onBlur={() => {
        history.replace(`/search/${serial}`)

      }}
      onChange={(e) => {
        setSerial(e.target.value.toUpperCase())
      }}
      onKeyPress={(e) => {
        if (e.key !== "Enter") {
          return
        }

        history.replace(`/search/${serial}`)
      }}
    />
  </div>;
}


export default React.memo(DeviceSearchComponent)