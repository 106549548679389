export default {
  roundDelta(val: number) {
    return Math.round(val * 1000) / 1000.0
  },

  round(val: number) {
    return Math.round(val * 1000) / 1000.0
  },
  toObserverisplayString(obs: string) {
    switch (obs.toUpperCase()) {
      case "TEN_DEGREE":
        return "10°"
      default:
      case "TWO_DEGREE":
        return "2°"
    }
  }
}